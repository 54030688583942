import { useEffect } from "react";
import { useLoadDrift } from "src/technical-integrations/drift/useLoadDrift";
import { useAccessToken } from "src/common/authorisation";
import { getConfig } from "src/common/config";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useCookie } from "react-use";
import { CookieStorage } from "src/common/constants/storage";

import { hideDrift, showDrift } from "./client/drift";

interface DriftApi {
  widget: {
    show: () => void;
    hide: () => void;
  };
  message: boolean;
}

declare global {
  interface Window {
    drift: {
      on: (action: string, callback: (api: DriftApi) => void) => void | boolean;
      identify: (user_id: string | undefined, traits?: {}) => void;
      api: DriftApi;
      load: (app_id: string) => void;
      reset: () => void;
      show: () => void;
      hide: () => void;
    };
  }
}
interface DriftProps {
  children: React.ReactNode;
}

export const Drift: React.FC<DriftProps> = ({ children }) => {
  const [details] = useAccessToken();
  // call useUserShowSelf with shouldMakeRequest=false if user is not logged in.
  const { data: userDetails } =
    useUserShowSelf(details !== undefined && details !== null) || {};
  const user = userDetails?.users;
  const { isDriftLoaded } = useLoadDrift(user);
  const driftAppId = getConfig().client.drift.appId;
  const [driftUserId] = useCookie(CookieStorage.DriftID);
  const userId = user?.id;
  const userEmail = user?.email;

  useEffect(() => {
    // Hide widget if user leave main page
    if (window?.location?.pathname === "/") {
      showDrift();
    } else {
      hideDrift();
    }
  });

  useEffect(() => {
    if (isDriftLoaded && window?.drift) {
      if (userId && userEmail && driftUserId !== userId) {
        window.drift.identify(userId, {
          email: userEmail,
        });
      }
      window.drift.load(driftAppId);
    }
  }, [isDriftLoaded, driftAppId, userEmail, userId, driftUserId]);

  return <>{children}</>;
};

export const resetDrift = (): void => {
  if (window.drift) {
    window.drift.reset();
    window.drift.hide();
  }
};

export default Drift;
