import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { ImportResource, ImportsType } from "@gocardless/api/dashboard/types";
import { HomeSchemeEnum } from "src/common/scheme";

export const BULK_CHANGE_BANNER_MANDATES_LIMIT = 30;

export const BulkChangeSchemes = {
  Ach: HomeSchemeEnum.Ach,
  Bacs: HomeSchemeEnum.Bacs,
  Becs: HomeSchemeEnum.Becs,
  BecsNz: HomeSchemeEnum.BecsNz,
  Sepa: HomeSchemeEnum.Sepa,
} as const;

export const EntryFlowContextValues = {
  EntryPointBanner: "Bulk import change banner",
  Unverified: "Unverified",
  ChoosingScheme: "Choosing scheme",
} as const;

export const RequestFlowContextValues = {
  SubmitRequestSummary: "Submit request summary",
  CollectionMethod: "Deed - FM or AM",
  IncorporatedEntity: "Deed - Incorporated Entity",
  UploadingDeed: "Deed - Submitting",
  ContactDetails: "Form - Contact details",
  ProviderDetails: "Form - Provider details",
  SunDetails: "Form - SUN details",
  PartnerIntegration: "Form - Partner",
  MigrationDetails: "Form - Migration details",
  RequestSubmitted: "Form - Request submitted",
} as const;

export const UploadFlowContextValues = {
  SepaMigrationType: "Form - SEPA migration type",
  ConfirmDate: "Upload - Confirm date",
  CollectingData: "Preparing file - Collecting data",
  DownloadCSV: "Preparing file - Download CSV",
  ExportCSV: "Preparing file - Export CSV",
  NotifyCustomer: "Preparing file - Notify customer",
  PreparingFile: "Preparing file",
  Upload2FA: "Upload - 2FA",
  UploadBackendFailed: "Upload - Backend failed",
  UploadFailed: "Upload - Failed",
  UploadFile: "Preparing file - Upload",
  ValidationFile: "Upload - Validation file",
} as const;

export type BulkChangeContextValue =
  | (typeof UploadFlowContextValues)[keyof typeof UploadFlowContextValues]
  | (typeof EntryFlowContextValues)[keyof typeof EntryFlowContextValues]
  | (typeof RequestFlowContextValues)[keyof typeof RequestFlowContextValues];

export type BulkChangeScheme =
  (typeof BulkChangeSchemes)[keyof typeof BulkChangeSchemes];

export interface BulkChangeEntryFormFields {
  scheme: BulkChangeScheme | undefined;
}

export interface SepaBulkChangeFlowFormFields {
  cidType: "gocardless_cid" | "custom_do_not_own" | "custom_own";
  usingUniqueMerchantReference: "true" | "false";
}

export interface TwoFactorAuthFormFields {
  otpCode: string;
}

export interface BulkChangeFlowProps {
  onContinue: () => void;
}

export const ZENDESK_FORM_URL =
  "https://manage.gocardless.com/forms/zendesk/134125";

export const GENERIC_CUSTOMER_HUB_URL =
  "https://hub.gocardless.com/s/article/Customer-migration-process";

export const getBulkChangeSchemeToLabelMap: (
  i18n: I18n
) => Record<BulkChangeScheme, string> = (i18n) => ({
  [BulkChangeSchemes.Ach]: i18n._(t({ message: "US Dollars (ACH)" })),
  [BulkChangeSchemes.Bacs]: i18n._(t({ message: "British Pounds (Bacs)" })),
  [BulkChangeSchemes.Becs]: i18n._(t({ message: "Australian Dollars (BECS)" })),
  [BulkChangeSchemes.BecsNz]: i18n._(
    t({ message: "New Zealand Dollars (BECS NZ)" })
  ),
  [BulkChangeSchemes.Sepa]: i18n._(t({ message: "Euros (SEPA)" })),
});

export const getBulkChangeSchemeFromImportType = (
  importType: ImportResource["type"]
) => {
  switch (importType) {
    case ImportsType.BulkChangeAch:
      return BulkChangeSchemes.Ach;
    case ImportsType.BulkChangeBacs:
      return BulkChangeSchemes.Bacs;
    case ImportsType.BulkChangeBecs:
      return BulkChangeSchemes.Becs;
    case ImportsType.BulkChangeBecsNz:
      return BulkChangeSchemes.BecsNz;
    case ImportsType.BulkChangeSepa:
    case ImportsType.BulkChangeSepaOwnCid:
    case ImportsType.BulkChangeSepaKeepReference:
    case ImportsType.BulkChangeSepaOwnCidKeepReference:
    case "bulk_change_sepa_new_cid" as ImportsType:
    case "bulk_change_sepa_own_cid_new_reference" as ImportsType:
      return BulkChangeSchemes.Sepa;
    default:
      return undefined;
  }
};

export const getImportTypeFromBulkChangeScheme = ({
  scheme,
  cidType,
  usingUniqueMerchantReference,
  customMandateReferencesEnabled,
}: SepaBulkChangeFlowFormFields & {
  scheme: BulkChangeScheme;
  customMandateReferencesEnabled: boolean;
}) => {
  switch (scheme) {
    case BulkChangeSchemes.Ach:
      return ImportsType.BulkChangeAch;
    case BulkChangeSchemes.Bacs:
      return ImportsType.BulkChangeBacs;
    case BulkChangeSchemes.Becs:
      return ImportsType.BulkChangeBecs;
    case BulkChangeSchemes.BecsNz:
      return ImportsType.BulkChangeBecsNz;
    case BulkChangeSchemes.Sepa: {
      switch (cidType) {
        case "gocardless_cid":
        case "custom_do_not_own": {
          if (!customMandateReferencesEnabled || !usingUniqueMerchantReference)
            return ImportsType.BulkChangeSepa;

          return ImportsType.BulkChangeSepaKeepReference;
        }
        case "custom_own": {
          if (!customMandateReferencesEnabled || !usingUniqueMerchantReference)
            return ImportsType.BulkChangeSepaOwnCid;

          return ImportsType.BulkChangeSepaOwnCidKeepReference;
        }
      }
    }
  }
};
