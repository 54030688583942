import { AppProps } from "next/app";
import Head from "next/head";
import { ThemeProvider, Toaster, Reset } from "@gocardless/flux-react";
import { SWRConfig } from "swr";
import "../common/api";
import { NextPageWithLayout } from "src/components/layout/types";
import { ThirdPartyTracking } from "src/technical-integrations/third-party-tracking/ThirdPartyTracking";
import { getConfig } from "src/common/config";
import { OutdatedBrowserBanner } from "src/common/outdated-browser-banner/OutdatedBrowserBanner";
import {
  TranscendContextProvider,
  TranscendCookieScript,
} from "src/technical-integrations/transcend";
import { GlobalExperimentDecisionProvider } from "src/technical-integrations/optimizely/GlobalExperimentsContext";
import { useRouterQuery } from "src/common/routing";
import { Segment } from "src/technical-integrations/segment";

import { LogAppVersion } from "../common/log-app-version/LogAppVersion";
import { GlobalStateProvider } from "../components/global-state/GlobalStateProvider";
import SentryErrorBoundary from "../components/SentryErrorBoundary";
import { I18nProvider } from "../components/i18n";
import "react-toastify/dist/ReactToastify.minimal.css";
import { ExportCentreContainer } from "../components/ui/export-centre/ExportCentreContainer";

import "@gocardless/flux-react/fonts.css";

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const AppContainer: React.FC<AppPropsWithLayout> = ({
  Component,
  pageProps,
}) => {
  const { buildId, environment } = getConfig().shared;
  const appVersion = `${environment}:${buildId}`;
  const getLayout = Component.getLayout ?? ((page) => page);

  const { item: fsbMemberDiscount } = useRouterQuery("fsb_member_discount");

  const ogTitle = fsbMemberDiscount
    ? "Get started with GoCardless"
    : "Get started for free with GoCardless";

  return (
    <SentryErrorBoundary>
      {/* Do not place any components that make API calls using our API package outside of SWRConfig */}
      <SWRConfig
        value={{
          revalidateOnFocus: false /* we only want this in development */,
          shouldRetryOnError: false /* we only want this in development */,
          refreshInterval: 0,
        }}
      >
        <TranscendContextProvider>
          <TranscendCookieScript />
          <ThirdPartyTracking />
          <Head>
            <title>GoCardless</title>
            <meta name="app-version" content={appVersion} />
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="GoCardless" />
            <meta
              name="twitter:image"
              content="https://gocardless.com/content-platform/static/816e9048c2a134da3e79b8677556ad0f/f8482/og-full.png"
            />
            <meta name="twitter:title" content={ogTitle} />
            <meta
              property="og:image"
              content="https://gocardless.com/content-platform/static/816e9048c2a134da3e79b8677556ad0f/f8482/og-full.png"
            />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:type" content="website" />
          </Head>
          <LogAppVersion />
          <Segment>
            <Reset />
            <I18nProvider>
              <Toaster />
              <OutdatedBrowserBanner />
              <ThemeProvider>
                <GlobalStateProvider>
                  <GlobalExperimentDecisionProvider>
                    <ExportCentreContainer />
                    {getLayout(<Component {...pageProps} />)}
                  </GlobalExperimentDecisionProvider>
                </GlobalStateProvider>
              </ThemeProvider>
            </I18nProvider>
          </Segment>
        </TranscendContextProvider>
      </SWRConfig>
    </SentryErrorBoundary>
  );
};

export default AppContainer;
